import React, { useState, useEffect, useContext } from "react";
import styles from "../../styles/flowTwo/StepTwo2.module.css";
import Header2 from "./Header2";
import Footer2 from "./Footer2";
import { useNavigate } from "react-router-dom";
import { FormDataContext } from "../../contexts/FormDataContext";

const DriverInfo = () => {
  const navigate = useNavigate();
  const { formData, setFormData } = useContext(FormDataContext);

  // Initialize drivers from local storage or default single-driver array
  const [drivers, setDrivers] = useState(() => {
    const savedDrivers = localStorage.getItem("drivers");
    return savedDrivers
      ? JSON.parse(savedDrivers)
      : [
          {
            firstName: "",
            lastName: "",
            driver: "",
            birth_date: "",
            insured: true,
            insuredForYear: true,
            sr_22: "0",
            dui: false,
            homeOwner: true,
            military: false,
            accidents: "0",
            credit_rating: "Good",
            maritalStatus: "Single",
            currentInsurer: "- Select One -",
          },
        ];
  });

  const [errors, setErrors] = useState({});
  const [questionsDisplayed, setQuestionsDisplayed] = useState(0); // Controls the number of visible questions

  // Determine traffic source and initialize appropriate pixel
  useEffect(() => {
    const dsp_name = localStorage.getItem("dsp_name");
    if (dsp_name === "facebook") {
      fbq("track", "ViewContent");
    }
  }, []);

  // Save drivers to local storage whenever drivers state changes
  useEffect(() => {
    localStorage.setItem("drivers", JSON.stringify(drivers));
  }, [drivers]);

  const handleDateChange = (index, e) => {
    let rawValue = e.target.value.replace(/[^0-9]/g, "");
    if (rawValue.length > 8) rawValue = rawValue.slice(0, 8);

    // Create a display value for the user (MM/DD/YYYY format as they type)
    let displayValue = rawValue;
    if (displayValue.length >= 3 && displayValue.length <= 4) {
      displayValue = `${displayValue.slice(0, 2)}/${displayValue.slice(2)}`;
    } else if (displayValue.length >= 5) {
      displayValue = `${displayValue.slice(0, 2)}/${displayValue.slice(
        2,
        4
      )}/${displayValue.slice(4)}`;
    }

    // Convert to YYYY-MM-DD if it's a complete MM/DD/YYYY date
    let updatedValue = displayValue;
    const fullDateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    if (fullDateRegex.test(displayValue)) {
      const [month, day, year] = displayValue.split("/");
      updatedValue = `${year}-${month}-${day}`; // Store in YYYY-MM-DD
    }

    const updatedDrivers = [...drivers];
    updatedDrivers[index].birth_date = updatedValue;
    setDrivers(updatedDrivers);
  };

  const capitalize = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleDriverChange = (index, field, value) => {
    const updatedDrivers = [...drivers];

    // Capitalize firstName and lastName
    if (field === "firstName" || field === "lastName") {
      value = capitalize(value);
    }

    // Update the specific field with the capitalized value
    updatedDrivers[index][field] = value;

    // Whenever firstName or lastName changes, update the driver field
    if (field === "firstName" || field === "lastName") {
      updatedDrivers[
        index
      ].driver = `${updatedDrivers[index].firstName} ${updatedDrivers[index].lastName}`;
    }

    // Update the state
    setDrivers(updatedDrivers);
  };

  const handleChange = (index, field, value) => {
    const updatedDrivers = [...drivers];
    updatedDrivers[index][field] = value;
    setDrivers(updatedDrivers);

    // If a question is answered, display the next two questions
    if (field !== "birth_date" && value !== "" && questionsDisplayed < 10) {
      setQuestionsDisplayed(questionsDisplayed + 2);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    let firstErrorFieldId = null;

    // Validate each driver
    drivers.forEach((driver, index) => {
      const prefix = `driver${index}`;

      // Validate firstName
      if (!driver.firstName || !/^[a-zA-Z]+$/.test(driver.firstName)) {
        newErrors[`${prefix}-firstName`] =
          "First name is required and must contain only letters.";
        if (!firstErrorFieldId) firstErrorFieldId = `${prefix}-firstName`;
      }

      // Validate lastName
      if (!driver.lastName || !/^[a-zA-Z]+$/.test(driver.lastName)) {
        newErrors[`${prefix}-lastName`] =
          "Last name is required and must contain only letters.";
        if (!firstErrorFieldId) firstErrorFieldId = `${prefix}-lastName`;
      }

      // Validate birth_date
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (!dateRegex.test(driver.birth_date)) {
        newErrors[`${prefix}-birth_date`] =
          "Please enter a valid date in MM-DD-YYYY format.";
        if (!firstErrorFieldId) firstErrorFieldId = `${prefix}-birth_date`;
      } else {
        // Further validate the date is real
        const [year, month, day] = driver.birth_date.split("-");
        const dateObj = new Date(year, month - 1, day);

        // Check if the parsed date matches the components (to avoid invalid dates like 2020-13-40)
        if (
          dateObj.getFullYear() !== parseInt(year, 10) ||
          dateObj.getMonth() + 1 !== parseInt(month, 10) ||
          dateObj.getDate() !== parseInt(day, 10)
        ) {
          newErrors[`${prefix}-birth_date`] = "Please enter a valid real date.";
          if (!firstErrorFieldId) firstErrorFieldId = `${prefix}-birth_date`;
        }
      }
    });

    setErrors(newErrors);

    // If there are errors, scroll to the first error field
    if (firstErrorFieldId) {
      const errorElement = document.getElementById(firstErrorFieldId);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      return false;
    }

    return true;
  };

  const handleNextStep = () => {
    if (validateForm()) {
      // Include the entire drivers array in formData
      setFormData({ ...formData, drivers });
      window.scrollTo(0, 0);
      navigate("/step-three2");
    }
  };

  const handleAddDriver = () => {
    setDrivers([
      ...drivers,
      {
        firstName: "",
        lastName: "",
        birth_date: "",
        insured: true,
        insuredForYear: true,
        sr_22: "0",
        dui: false,
        homeOwner: true,
        military: false,
        accidents: "0",
        credit_rating: "Good",
        maritalStatus: "Single",
        currentInsurer: "- Select One -",
      },
    ]);
  };

  const handleRemoveDriver = (index) => {
    const updatedDrivers = drivers.filter((_, i) => i !== index);
    setDrivers(updatedDrivers);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <Header2 />
      <div className="max-w-5xl mx-auto py-10 px-6">
        <h2 className="text-2xl sm:text-5xl font-bold text-gray-800 mt-0 mb-4 text-center tracking-tight leading-snug">
          9 out of 10 users unlocked savings of $300 or more by verifying their
          quotes—start saving today!
        </h2>
        <div className="w-full bg-gray-200 rounded-full h-4 mt-4">
          <div
            className="bg-green-600 h-4 rounded-full"
            style={{ width: "60%" }}
          ></div>
        </div>

        {drivers.map((driver, index) => {
          const prefix = `driver${index}`;
          return (
            <div
              key={index}
              className="bg-white p-8 rounded-xl shadow-lg mb-8 transition-transform transform hover:scale-105"
            >
              {/* First Name */}
              <div className="mb-4">
                <h3 className="text-lg font-semibold text-gray-800 mb-4">
                  Driver Details
                </h3>
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor={`${prefix}-firstName`}
                >
                  First Name
                </label>
                <input
                  id={`${prefix}-firstName`}
                  type="text"
                  value={driver.firstName}
                  onChange={(e) =>
                    handleChange(index, "firstName", e.target.value)
                  }
                  className={`mt-1 block w-full rounded-md border ${
                    errors[`${prefix}-firstName`]
                      ? "border-red-500"
                      : "border-gray-300"
                  } shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm`}
                />
                {errors[`${prefix}-firstName`] && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors[`${prefix}-firstName`]}
                  </p>
                )}
              </div>

              {/* Last Name */}
              <div className="mb-4">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor={`${prefix}-lastName`}
                >
                  Last Name
                </label>
                <input
                  id={`${prefix}-lastName`}
                  type="text"
                  value={driver.lastName}
                  onChange={(e) =>
                    handleChange(index, "lastName", e.target.value)
                  }
                  className={`mt-1 block w-full rounded-md border ${
                    errors[`${prefix}-lastName`]
                      ? "border-red-500"
                      : "border-gray-300"
                  } shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm`}
                />
                {errors[`${prefix}-lastName`] && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors[`${prefix}-lastName`]}
                  </p>
                )}
              </div>

              {/* Date of Birth */}
              <div className="mb-4">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor={`${prefix}-birth_date`}
                >
                  Date of Birth
                </label>
                <input
                  id={`${prefix}-birth_date`}
                  type="text"
                  value={
                    /^\d{4}-\d{2}-\d{2}$/.test(driver.birth_date)
                      ? (() => {
                          const [year, month, day] =
                            driver.birth_date.split("-");
                          return `${month}/${day}/${year}`;
                        })()
                      : driver.birth_date
                  }
                  onChange={(e) => handleDateChange(index, e)}
                  className={`mt-1 block w-full rounded-md border ${
                    errors[`${prefix}-birth_date`]
                      ? "border-red-500"
                      : "border-gray-300"
                  } shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm`}
                  placeholder="MM/DD/YYYY"
                />
                {errors[`${prefix}-birth_date`] && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors[`${prefix}-birth_date`]}
                  </p>
                )}
              </div>

              {/* Additional Fields */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {/* Insurance Toggle */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Are you currently insured?
                  </label>
                  <div className="flex space-x-4 mt-2">
                    <button
                      className={`px-4 py-2 rounded-md font-medium ${
                        driver.insured
                          ? "bg-green-500 text-white"
                          : "bg-gray-200 text-gray-700"
                      }`}
                      onClick={() => handleChange(index, "insured", true)}
                    >
                      Yes
                    </button>
                    <button
                      className={`px-4 py-2 rounded-md font-medium ${
                        !driver.insured
                          ? "bg-red-500 text-white"
                          : "bg-gray-200 text-gray-700"
                      }`}
                      onClick={() => handleChange(index, "insured", false)}
                    >
                      No
                    </button>
                  </div>
                </div>

                {/* Current Insurer */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Current Insurer
                  </label>
                  <select
                    value={driver.currentInsurer}
                    onChange={(e) => {
                      localStorage.setItem("current_company", e.target.value);
                      handleChange(index, "currentInsurer", e.target.value);
                    }}
                    className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                  >
                    {[
                      "- Select One -",
                      "Geico",
                      "State Farm",
                      "Progressive",
                      "Allstate",
                      "Liberty Mutual",
                      "USAA",
                      "Farmers",
                      "Nationwide",
                      "American Family",
                      "Travelers",
                      "Other",
                    ].map((insurer) => (
                      <option key={insurer} value={insurer}>
                        {insurer}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="mb-6">
                {/* SR22 Notification */}
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Has your state notified you to file an SR22?
                  </label>
                  <div className="flex space-x-4 mt-2">
                    <button
                      className={`px-4 py-2 rounded-md font-medium transition-colors ${
                        driver.sr_22 === "1"
                          ? "bg-green-500 text-white"
                          : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleChange(index, "sr_22", "1");
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className={`px-4 py-2 rounded-md font-medium transition-colors ${
                        driver.sr_22 === "0"
                          ? "bg-green-500 text-white"
                          : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleChange(index, "sr_22", "0");
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>

                {/* Military Affiliation */}
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Military Affiliation?
                  </label>
                  <div className="flex space-x-4 mt-2">
                    <button
                      className={`px-4 py-2 rounded-md font-medium transition-colors ${
                        driver.military
                          ? "bg-green-500 text-white"
                          : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                      }`}
                      onClick={() => handleChange(index, "military", true)}
                    >
                      Yes
                    </button>
                    <button
                      className={`px-4 py-2 rounded-md font-medium transition-colors ${
                        !driver.military
                          ? "bg-green-500 text-white"
                          : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                      }`}
                      onClick={() => handleChange(index, "military", false)}
                    >
                      No
                    </button>
                  </div>
                </div>

                {/* Credit Score */}
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Credit Score
                  </label>
                  <select
                    value={driver.credit_rating}
                    onChange={(e) =>
                      handleChange(index, "credit_rating", e.target.value)
                    }
                    className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                  >
                    {[
                      "Excellent",
                      "Good",
                      "Average",
                      "Below average",
                      "Poor",
                    ].map((score) => (
                      <option key={score} value={score}>
                        {score}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {index > 0 && (
                <button
                  type="button"
                  onClick={() => handleRemoveDriver(index)}
                  className={styles["add-driver"]}
                  style={{ marginBottom: "20px" }}
                >
                  - Remove Driver
                </button>
              )}
            </div>
          );
        })}

        <button
          type="button"
          onClick={handleAddDriver}
          className={styles["add-driver"]}
          style={{ marginBottom: "20px" }}
        >
          + Add Another Driver
        </button>

        <button
          className="inline-block py-2 px-6 bg-green-600 hover:bg-green-700 text-white font-semibold rounded"
          onClick={handleNextStep}
        >
          Next Step
        </button>
      </div>
      <Footer2 />
    </div>
  );
};

export default DriverInfo;
