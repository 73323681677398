import React from "react";

const DeviceContext = React.createContext();

export const DeviceProvider = ({ children }) => {
  const value = { isMobile: false }; // Example value
  return (
    <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>
  );
};

export const useDevice = () => {
  const context = React.useContext(DeviceContext);
  if (!context) {
    throw new Error("useDevice must be used within a DeviceProvider");
  }
  return context;
};
