const Footer = () => {
  return (
    <footer className="bg-gray-100 py-10">
      <div className="container mx-auto px-6 lg:px-14">
        {/* Top Section */}
        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center border-b border-gray-300 pb-10 mb-8 space-y-6 lg:space-y-0">
          {/* Left: Diversity & Privacy Links */}
          <div className="space-y-4">
            <div className="flex items-center space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-purple-600"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 15h-2v-6h2v6zm4 0h-2v-6h2v6zm0-8h-2V7h2v2zm-4 0h-2V7h2v2z" />
              </svg>
              <a
                href="#"
                className="text-gray-800 font-semibold hover:underline"
              >
                Learn more how USA Insurance Reviews is committed
              </a>
            </div>
            <div className="flex items-center space-x-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-blue-600"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 15h-2v-6h2v6zm4 0h-2v-6h2v6zm0-8h-2V7h2v2zm-4 0h-2V7h2v2z" />
              </svg>
              <a
                href="#"
                className="text-gray-800 font-semibold hover:underline"
              >
                Your privacy choices
              </a>
            </div>
          </div>

          {/* Right: App Store & Social Media Links */}
          <div className="flex flex-col lg:flex-row items-center space-y-4 lg:space-y-0 lg:space-x-8">
            {/* App Store Links */}
            <div className="flex space-x-4">
              <a href="#">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg"
                  alt="App Store"
                  className="h-10"
                />
              </a>
              <a href="#">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/Google_Play_Store_badge_EN.svg/512px-Google_Play_Store_badge_EN.svg.png"
                  alt="Google Play"
                  className="h-10"
                />
              </a>
            </div>

            {/* Social Media Icons */}
            <div className="flex space-x-6">
              <a href="#" className="hover:text-gray-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-600"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M18 2.01L6 2c-2.21 0-4 1.79-4 4v12c0 2.21 1.79 4 4 4h12c2.21 0 4-1.79 4-4V6c0-2.21-1.79-4-4-4zm-4.09 7.88h-2.38V18h-3.25v-8.13H6.62V7h1.66l.02-.92c.06-.74.14-1.45.28-2.13h2.44v3.6h2.36v3.33z" />
                </svg>
              </a>
              <a href="#" className="hover:text-gray-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-600"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-2 8h-2.5V16H14V10H9.5V8H14V6c0-1.1.9-2 2-2h2v2h-2c-.5 0-.5.5-.5 1v1.5H18v2z" />
                </svg>
              </a>
              <a href="#" className="hover:text-gray-800">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-600"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M21.6 8.2s-.4-2.7-1.9-3.8c-1.3-1-2.8-.9-3.4-.9-2.6.1-5.2 1.1-6.6 2.6-.9.8-1.6 1.7-2.2 3-.7 1.4-.8 2.9-.8 2.9H4.6c0-2.2 2.6-6 6-6H12s2-.5 2.8-.5c.8 0 1.3.5 2 1 .6.5.7.6 1 .6.4 0 .8-.4.8-.4s.1-.5.2-.5c.4-.2.6-.5.8-.8.2-.3.4-.6.5-.8.2-.3.4-.7.4-.9.1-.2.1-.4.2-.5.1-.2 0-.3 0-.3z" />
                </svg>
              </a>
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="text-sm text-gray-500 text-center lg:text-left space-y-4">
          <div className="flex flex-wrap justify-center lg:justify-start space-x-4 mb-4">
            <a href="#" className="hover:text-gray-700">
              Legal Terms & Conditions
            </a>
            <a href="#" className="hover:text-gray-700">
              Privacy Center
            </a>
            <a href="#" className="hover:text-gray-700">
              U.S. Data Privacy Policy
            </a>
            <a href="#" className="hover:text-gray-700">
              Press
            </a>
            <a href="#" className="hover:text-gray-700">
              Careers
            </a>
            <a href="#" className="hover:text-gray-700">
              Contact Us
            </a>
          </div>
          <p>&copy; 2025 USA Insurance Reviews. All rights reserved.</p>
          <p>
            USA Insurance Reviews and the USA Insurance Reviews trademarks used
            herein are trademarks or registered trademarks of USA Insurance
            Reviews and its affiliates. Other product and company names
            mentioned are the property of their respective owners.{" "}
            <a href="#" className="text-blue-600 hover:underline">
              Licenses and Disclosures
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
