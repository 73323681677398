import { useState } from "react";

const HowItWorks = () => {
  const [activeStep, setActiveStep] = useState(1);

  const steps = [
    {
      id: 1,
      title: "Get matched to new policies",
      description:
        "Just answer some questions about your current plan so we can match you to personalized quotes.",
      image:
        "https://assets.experiancs.com/images/graphic-auto-insurance-step-1-questions-screen-d.webp?hs=433f034ed1bf0f01376cd289d5754692",
    },
    {
      id: 2,
      title: "Pick the one you want",
      description:
        "We'll show you multiple offers from our top insurance carriers so you can choose the best one for you.",
      image:
        "https://assets.experiancs.com/images/graphic-auto-insurance-step-2-matches-screen-d.webp?hs=9210dd41aa614db2b34dcac5949419a9",
    },
    {
      id: 3,
      title: "Let us do the rest",
      description:
        "From switching your policy to canceling and requesting a partial refund from your old plan, we help you do it all.",
      image:
        "https://assets.experiancs.com/images/graphic-auto-insurance-step-3-advisor-screen-d.webp?hs=0ece547849f1a8d24a3b75fb8127ca31",
    },
  ];

  return (
    <section className="py-12 mb-20">
      <div className="container mx-auto px-6 lg:px-14">
        <h2 className="text-3xl md:text-5xl font-semibold mb-8 text-gray-800">
          How it works:
        </h2>

        {/* Desktop Layout */}
        <div className="hidden md:flex flex-col-reverse md:flex-row items-start md:items-start relative">
          {/* Step Section */}
          <div className="w-full md:w-1/2 space-y-7 py-6 md:py-8">
            {steps.map((step) => (
              <div
                key={step.id}
                className={`p-6 rounded-lg shadow-md border cursor-pointer transition-all ${
                  activeStep === step.id
                    ? "bg-blue-950 text-white"
                    : "text-gray-800"
                }`}
                onMouseEnter={() => setActiveStep(step.id)}
              >
                <h3 className="text-lg font-bold">
                  <span className="text-2xl mr-2">{step.id}.</span> {step.title}
                </h3>
                <p className="text-sm">{step.description}</p>
              </div>
            ))}
          </div>

          {/* Image Section */}
          <div className="w-full md:w-1/2 flex justify-center md:ml-8 relative h-[400px] md:h-[500px] lg:h-[700px]">
            {steps.map((step) => (
              <img
                key={step.id}
                src={step.image}
                alt={`Step ${step.id} Image`}
                className={`absolute w-full max-w-xs md:max-w-md lg:max-w-lg rounded-lg transition-all duration-[900ms] ease-in-out transform ${
                  activeStep === step.id
                    ? "opacity-100 translate-y-[-100px]"
                    : "opacity-0 translate-y-80"
                }`}
              />
            ))}
          </div>
        </div>

        {/* Mobile Layout */}
        <div className="md:hidden space-y-10">
          {steps.map((step) => (
            <div
              key={step.id}
              className="bg-white rounded-lg shadow-md overflow-hidden"
            >
              {/* Content */}
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-800 mb-2">
                  {step.id}. {step.title}
                </h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
              {/* Image */}
              <img
                src={step.image}
                alt={`Step ${step.id} Image`}
                className="w-full h-64 object-cover"
                style={{ objectPosition: "50% 42%" }} // Horizontal center, 20% down from the top
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
