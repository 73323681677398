import React from "react";
import {
  StarIcon as SolidStarIcon, // Filled star
} from "@heroicons/react/24/solid";
import {
  StarIcon as OutlineStarIcon, // Outline (empty) star
} from "@heroicons/react/24/outline";

// Helper: partial-star rating for decimals (4.2, etc.)
function PartialStar({ fraction }) {
  return (
    <div className="relative w-5 h-5 inline-block">
      {/* Empty (outline) star */}
      <OutlineStarIcon className="absolute text-gray-300 w-5 h-5" />
      {/* Filled (solid) star, clipped by fraction */}
      <div
        className="overflow-hidden absolute top-0 left-0 h-5"
        style={{ width: `${fraction * 100}%` }}
      >
        <SolidStarIcon className="text-yellow-400 w-5 h-5" />
      </div>
    </div>
  );
}

// Renders 5 partial stars for a decimal rating
function StarRating({ rating }) {
  // e.g., rating = 4.2 => 4 full stars + 1 partial
  const stars = [0, 1, 2, 3, 4].map((i) => {
    const starValue = rating - i; // how much star #i is filled
    const fraction = Math.max(0, Math.min(1, starValue));
    return <PartialStar key={i} fraction={fraction} />;
  });

  return <div className="flex space-x-1">{stars}</div>;
}

// Displays a rating distribution bar (e.g., "5 stars: 63%")
function RatingDistributionBar({ stars, percentage }) {
  return (
    <div className="flex items-center space-x-2">
      <div className="text-sm font-medium">{stars}</div>
      <SolidStarIcon className="h-4 w-4 text-yellow-400" aria-hidden="true" />
      <div className="relative w-48 h-2 bg-gray-200 rounded overflow-hidden">
        <div
          className="absolute left-0 top-0 h-2 bg-yellow-400 rounded 
                     transition-all duration-700"
          style={{ width: `${percentage}%` }}
        />
      </div>
      <div className="text-sm text-gray-500">{percentage}%</div>
    </div>
  );
}

export default function AdvancedReviews() {
  // Example data
  const totalReviews = 1624;
  const overallRating = 4.9;
  const ratingDistribution = [
    { stars: 5, percentage: 63 },
    { stars: 4, percentage: 10 },
    { stars: 3, percentage: 6 },
    { stars: 2, percentage: 12 },
    { stars: 1, percentage: 9 },
  ];

  // Example user reviews
  const reviews = [
    {
      name: "Emily Selman",
      avatar:
        "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-4.0.3&auto=format&fit=crop&w=80&q=60",
      rating: 5,
      text: "I was skeptical at first, but this site found me a policy that was $30 cheaper per month than my old plan. On top of that, the coverage is better! I always thought searching for cheaper car insurance was a hassle, but this made it incredibly easy and saved me over $300 a year.",
    },
    {
      name: "Hector Gibbons",
      avatar:
        "https://images.unsplash.com/photo-1499714608240-22fc6ad53fb2?ixlib=rb-4.0.3&auto=format&fit=crop&w=80&q=60",
      rating: 5,
      text: "After putting in my ZIP code, I received multiple quotes from insurance companies within minutes. I picked an offer that ended up cutting my monthly bill by 20%. Highly recommend it!",
    },
    {
      name: "Mark Edwards",
      avatar:
        "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&auto=format&fit=crop&w=80&q=60",
      rating: 4.2,
      text: "I typed in my info and got a side-by-side comparison of different providers. I ended up switching and now save nearly $25 a month. Hassle-free, plus the customer service from my new insurer is top-notch.",
    },
  ];

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-lg">
      {/* Top section: overall rating */}
      <div className="mb-6 flex flex-col items-center text-center">
        {/* Overall rating row */}
        <div className="flex items-center justify-center space-x-3">
          {/* Large rating number */}
          <span className="text-3xl font-bold text-gray-900">
            {overallRating.toFixed(1)}
          </span>
          <div>
            <StarRating rating={overallRating} />
            <p className="text-sm text-gray-600">
              Based on {totalReviews.toLocaleString()} reviews
            </p>
          </div>
        </div>
      </div>

      {/* Rating distribution chart */}
      <div className="mb-6 space-y-2 flex flex-col items-center text-center">
        {ratingDistribution.map(({ stars, percentage }) => (
          <RatingDistributionBar
            key={stars}
            stars={stars}
            percentage={percentage}
          />
        ))}
      </div>

      {/* CTA / short text */}
      <div className="mb-6">
        <p className="text-sm text-gray-600">
          Join the millions of Americans who have already saved money on their
          car insurance.
        </p>
      </div>

      <hr className="my-6 border-gray-200" />

      {/* List of reviews - each centered */}
      <div className="space-y-8">
        {reviews.map((review, idx) => (
          <div
            key={idx}
            className="flex flex-col items-center text-center space-y-2 py-6 last:pb-0"
          >
            {/* Avatar centered */}
            <img
              src={review.avatar}
              alt={review.name}
              className="h-12 w-12 rounded-full object-cover"
            />

            {/* Name & stars */}
            <div className="flex items-center space-x-2">
              <h4 className="font-semibold text-gray-800">{review.name}</h4>
              <StarRating rating={review.rating} />
            </div>

            {/* Review text */}
            <p className="mt-2 text-gray-700 leading-relaxed max-w-md">
              {review.text}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
