import React, { useState, useEffect, useContext } from "react";
import Header2 from "./Header2";
import Footer2 from "./Footer2";
import { vehicleMakes } from "../../constants/vehicleMakes";
import { vehicleModel } from "../../constants/vehicleModel";
import { vehicleType } from "../../constants/vehicleType";
import { useNavigate } from "react-router-dom";
import { FormDataContext } from "../../contexts/FormDataContext";

const StepOne2 = () => {
  const navigate = useNavigate();
  const { formData, setFormData } = useContext(FormDataContext);

  const [vehicles, setVehicles] = useState(() => {
    const savedVehicles = localStorage.getItem("vehicles");
    return savedVehicles
      ? JSON.parse(savedVehicles)
      : [
          {
            year: "",
            make: "",
            model: "",
            submodel: "",
            annual_mileage: "",
            primary_purpose: "",
          },
        ];
  });

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    localStorage.setItem("vehicles", JSON.stringify(vehicles));
  }, [vehicles]);

  // Handle user input
  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedVehicles = [...vehicles];
    updatedVehicles[index][name] = value;

    // Reset dependent fields if a parent field changes
    if (name === "make") {
      updatedVehicles[index].model = "";
      updatedVehicles[index].submodel = "";
    } else if (name === "model") {
      updatedVehicles[index].submodel = "";
    }

    setVehicles(updatedVehicles);

    const updatedErrors = [...errors];
    updatedErrors[index] = {
      ...updatedErrors[index],
      [name]: false,
    };
    setErrors(updatedErrors);
  };

  // Validate fields for each vehicle
  const validateFields = () => {
    const newErrors = [];
    let firstErrorFieldId = null;

    vehicles.forEach((vehicle, index) => {
      const vehicleErrors = {};

      if (!vehicle.year) {
        vehicleErrors.year = true;
        if (!firstErrorFieldId) firstErrorFieldId = `year-${index}`;
      }
      if (!vehicle.make) {
        vehicleErrors.make = true;
        if (!firstErrorFieldId) firstErrorFieldId = `make-${index}`;
      }
      if (!vehicle.model) {
        vehicleErrors.model = true;
        if (!firstErrorFieldId) firstErrorFieldId = `model-${index}`;
      }
      if (!vehicle.annual_mileage) {
        vehicleErrors.annual_mileage = true;
        if (!firstErrorFieldId) firstErrorFieldId = `annual_mileage-${index}`;
      }
      if (!vehicle.primary_purpose) {
        vehicleErrors.primary_purpose = true;
        if (!firstErrorFieldId) firstErrorFieldId = `primary_purpose-${index}`;
      }

      newErrors.push(vehicleErrors);
    });

    setErrors(newErrors);

    // Scroll to the first invalid field
    if (firstErrorFieldId) {
      const errorElement = document.getElementById(firstErrorFieldId);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      return false;
    }

    return true;
  };

  // Add new vehicle
  const handleAddVehicle = () => {
    setVehicles([
      ...vehicles,
      {
        year: "",
        make: "",
        model: "",
        submodel: "",
        annual_mileage: "",
        primary_purpose: "",
      },
    ]);
    setErrors([...errors, {}]);
  };

  // Remove vehicle
  const handleRemoveVehicle = (index) => {
    const updatedVehicles = vehicles.filter((_, i) => i !== index);
    const updatedErrors = errors.filter((_, i) => i !== index);
    setVehicles(updatedVehicles);
    setErrors(updatedErrors);
  };

  // Submit logic
  const handleSubmit = () => {
    if (validateFields()) {
      setFormData({ ...formData, vehicles });
      navigate("/step-two2");
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <Header2 />

      <div className="flex-1 px-4 py-6 max-w-4xl mx-auto w-full">
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mt-2 text-center">
          Get the Best Rates in Your Area!
        </h2>

        <div className="w-full bg-gray-200 rounded-full h-4 mt-4">
          <div
            className="bg-green-600 h-4 rounded-full"
            style={{ width: "33%" }}
          ></div>
        </div>

        <p className="text-gray-700 mb-6 text-bottom mt-4 text-center">
          Complete your quote in three simple steps!
        </p>

        {vehicles.map((vehicle, index) => (
          <div key={index} className="mb-8 p-4 bg-white shadow rounded-lg">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">
              Vehicle Details
            </h3>

            {/* Year */}
            <div className="mb-4">
              <label
                htmlFor={`year-${index}`}
                className="block mb-1 font-medium text-gray-700"
              >
                Vehicle Year
                {errors[index]?.year && (
                  <span className="text-red-600 ml-2">* Required</span>
                )}
              </label>
              <select
                id={`year-${index}`}
                name="year"
                value={vehicle.year}
                onChange={(e) => handleChange(index, e)}
                className={`w-full border rounded px-3 py-2 ${
                  errors[index]?.year ? "border-red-600" : "border-gray-300"
                }`}
              >
                <option value="">Please Select</option>
                {Array.from(
                  new Array(71),
                  (_, i) => new Date().getFullYear() - i
                ).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>

            {/* Make */}
            <div className="mb-4">
              <label
                htmlFor={`make-${index}`}
                className="block mb-1 font-medium text-gray-700"
              >
                Vehicle Make
                {errors[index]?.make && (
                  <span className="text-red-600 ml-2">* Required</span>
                )}
              </label>
              <select
                id={`make-${index}`}
                name="make"
                value={vehicle.make}
                onChange={(e) => handleChange(index, e)}
                className={`w-full border rounded px-3 py-2 ${
                  errors[index]?.make ? "border-red-600" : "border-gray-300"
                }`}
              >
                <option value="">Please Select</option>
                {vehicleMakes.map((make) => (
                  <option key={make} value={make}>
                    {make}
                  </option>
                ))}
              </select>
            </div>

            {/* Model */}
            <div className="mb-4">
              <label
                htmlFor={`model-${index}`}
                className="block mb-1 font-medium text-gray-700"
              >
                Vehicle Model
                {errors[index]?.model && (
                  <span className="text-red-600 ml-2">* Required</span>
                )}
              </label>
              <select
                id={`model-${index}`}
                name="model"
                value={vehicle.model}
                onChange={(e) => handleChange(index, e)}
                disabled={!vehicle.make}
                className={`w-full border rounded px-3 py-2 ${
                  errors[index]?.model ? "border-red-600" : "border-gray-300"
                }`}
              >
                <option value="">Please Select</option>
                {vehicleModel[vehicle.make]?.map((model) => (
                  <option key={model} value={model}>
                    {model}
                  </option>
                ))}
              </select>
            </div>

            {/* Vehicle Type (Optional) */}
            <div className="mb-4">
              <label
                htmlFor={`submodel-${index}`}
                className="block mb-1 font-medium text-gray-700"
              >
                Vehicle Type (Optional)
              </label>
              <select
                id={`submodel-${index}`}
                name="submodel"
                value={vehicle.submodel}
                onChange={(e) => handleChange(index, e)}
                disabled={!vehicle.model}
                className="w-full border rounded px-3 py-2 border-gray-300"
              >
                <option value="">Optional</option>
                {vehicleType[vehicle.make]?.[vehicle.model]?.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>

            {/* Annual Mileage */}
            <div className="mb-4">
              <label
                htmlFor={`annual_mileage-${index}`}
                className="block mb-1 font-medium text-gray-700"
              >
                Annual Mileage
                {errors[index]?.annual_mileage && (
                  <span className="text-red-600 ml-2">* Required</span>
                )}
              </label>
              <select
                id={`annual_mileage-${index}`}
                name="annual_mileage"
                value={vehicle.annual_mileage}
                onChange={(e) => handleChange(index, e)}
                className={`w-full border rounded px-3 py-2 ${
                  errors[index]?.annual_mileage
                    ? "border-red-600"
                    : "border-gray-300"
                }`}
              >
                <option value="">Please Select</option>
                {[...Array(10).keys()].map((i) => {
                  const miles = `${i * 5 + 5},000 miles`;
                  return (
                    <option key={miles} value={miles}>
                      {miles}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* Primary Use */}
            <div className="mb-4">
              <label
                htmlFor={`primary_purpose-${index}`}
                className="block mb-1 font-medium text-gray-700"
              >
                Primary Use
                {errors[index]?.primary_purpose && (
                  <span className="text-red-600 ml-2">* Required</span>
                )}
              </label>
              <select
                id={`primary_purpose-${index}`}
                name="primary_purpose"
                value={vehicle.primary_purpose}
                onChange={(e) => handleChange(index, e)}
                className={`w-full border rounded px-3 py-2 ${
                  errors[index]?.primary_purpose
                    ? "border-red-600"
                    : "border-gray-300"
                }`}
              >
                <option value="">Please Select</option>
                <option value="Commute to Work">Commute to Work</option>
                <option value="Pleasure">Pleasure</option>
                <option value="Business">Business</option>
                <option value="Other">Other</option>
              </select>
            </div>

            {/* Remove Vehicle */}
            {index > 0 && (
              <button
                type="button"
                onClick={() => handleRemoveVehicle(index)}
                className="text-red-600 hover:text-red-800 font-medium mt-2 inline-block"
              >
                - Remove Vehicle
              </button>
            )}
          </div>
        ))}

        {/* Add Vehicle Button */}
        <button
          type="button"
          onClick={handleAddVehicle}
          className="mb-6 inline-block py-2 px-4 text-white font-medium rounded bg-blue-600 hover:bg-blue-700 transition-colors duration-200"
        >
          + Add Another Vehicle
        </button>

        {/* Submit Button */}
        <button
          type="button"
          onClick={handleSubmit}
          className="inline-block py-2 px-6 bg-green-600 hover:bg-green-700 text-white font-semibold rounded"
        >
          Next Step
        </button>
      </div>

      <Footer2 />
    </div>
  );
};

export default StepOne2;
