import React, { useState, useEffect, useRef, useContext } from "react";
import Header2 from "./Header2";
import Footer2 from "./Footer2";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { FormDataContext } from "../../contexts/FormDataContext";

const StepThree2 = () => {
  const navigate = useNavigate();
  const { formData, setFormData } = useContext(FormDataContext);

  // State initialization
  const [contact, setContact] = useState(formData.contact || "");
  const [email, setEmail] = useState(formData.email || "");
  const [phone, setPhone] = useState(formData.phone || "");
  const [address, setAddress] = useState(formData.address || "");
  const [zipCode, setZipCode] = useState(formData.zip || "");
  const [selectedCoverage, setSelectedCoverage] = useState(
    formData.coverage_type || "State Minimum"
  );
  const [isHuman, setIsHuman] = useState(formData.isHuman || false);
  const [errors, setErrors] = useState({});
  const autocompleteRef = useRef(null);

  // Google Places Autocomplete
  useEffect(() => {
    if (window.google && window.google.maps) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        autocompleteRef.current,
        { types: ["address"], componentRestrictions: { country: "us" } }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place && place.address_components) {
          const zip = place.address_components.find((comp) =>
            comp.types.includes("postal_code")
          )?.long_name;

          const formattedAddress = place.formatted_address;

          setAddress(formattedAddress || "");
          setZipCode(zip || "");
        }
      });
    }
  }, []);

  // Sync local state with formData
  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      contact,
      email,
      phone,
      address,
      zip: zipCode,
      coverage_type: selectedCoverage,
      isHuman,
    }));
  }, [contact, email, phone, address, zipCode, selectedCoverage, isHuman]);

  // Validate form fields
  const validateForm = () => {
    const newErrors = {};

    if (!address.trim()) newErrors.address = "Street Address is required.";
    if (!zipCode.trim() || zipCode.length < 5)
      newErrors.zipCode = "ZIP Code is required and must be valid.";
    if (!email.trim() || !/\S+@\S+\.\S+/.test(email))
      newErrors.email = "A valid email address is required.";
    if (!phone.trim() || phone.includes("_"))
      newErrors.phone = "A valid phone number is required.";
    if (!selectedCoverage)
      newErrors.coverage = "Please select a coverage option.";
    if (!isHuman) newErrors.isHuman = "Please confirm you are human.";

    setErrors(newErrors);

    // Scroll to first error if validation fails
    if (Object.keys(newErrors).length > 0) {
      const firstErrorFieldId = Object.keys(newErrors)[0];
      const errorElement = document.getElementById(firstErrorFieldId);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      return false;
    }

    return true;
  };

  // Handle form submission
  const handleNextStep = (e) => {
    e.preventDefault();
    if (validateForm()) {
      window.scrollTo(0, 0);
      navigate("/loading-deals2");
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Header2 />
      <div className="max-w-3xl mx-auto py-10 px-6 bg-white rounded-lg shadow-lg">
        <h3 className="text-3xl font-bold text-gray-800 mb-6 text-center">
          You're Almost Done!
        </h3>
        <div className="w-full bg-gray-200 rounded-full h-4 mt-4">
          <div
            className="bg-green-600 h-4 rounded-full"
            style={{ width: "90%" }}
          ></div>
        </div>
        <form className="space-y-6">
          {/* Address */}
          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-4 mt-4">
              Final Steps
            </h3>
            <label
              htmlFor="address"
              className="block text-sm font-medium text-gray-700"
            >
              Street Address
            </label>
            <input
              id="address"
              ref={autocompleteRef}
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Start typing your address..."
              className={`mt-1 block w-full rounded-md border ${
                errors.address ? "border-red-500" : "border-gray-300"
              } shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm`}
            />
            {errors.address && (
              <span className="text-red-500 text-sm">{errors.address}</span>
            )}
          </div>

          {/* Email */}
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email Address
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Ex: john@email.com"
              className={`mt-1 block w-full rounded-md border ${
                errors.email ? "border-red-500" : "border-gray-300"
              } shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm`}
            />
            {errors.email && (
              <span className="text-red-500 text-sm">{errors.email}</span>
            )}
          </div>

          {/* Phone */}
          <div>
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-gray-700"
            >
              Phone Number
            </label>
            <InputMask
              id="phone"
              mask="(999) 999-9999"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="(xxx) xxx-xxxx"
              className={`mt-1 block w-full rounded-md border ${
                errors.phone ? "border-red-500" : "border-gray-300"
              } shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm`}
            >
              {(inputProps) => <input {...inputProps} type="tel" />}
            </InputMask>
            {errors.phone && (
              <span className="text-red-500 text-sm">{errors.phone}</span>
            )}
          </div>

          {/* Human Confirmation */}
          <div className="flex items-center">
            <input
              type="checkbox"
              id="human-check"
              checked={isHuman}
              onChange={(e) => setIsHuman(e.target.checked)}
              className="h-4 w-4 text-green-600 border-gray-300 rounded focus:ring-green-500"
            />
            <label htmlFor="human-check" className="ml-2 text-sm text-gray-700">
              I am not a robot
            </label>
          </div>
          {errors.isHuman && (
            <span className="text-red-500 text-sm">{errors.isHuman}</span>
          )}

          {/* Coverage Options */}
          <div>
            <h4 className="text-lg font-semibold text-gray-800">
              What type of rates do you want to see?
            </h4>
            <p className="text-sm text-gray-600 mb-4">
              Select a coverage option.
            </p>
            <div className="grid grid-cols-3 gap-4">
              {[
                "State Minimum",
                "Standard Protection",
                "Superior Protection",
              ].map((option) => (
                <button
                  key={option}
                  type="button"
                  onClick={() => setSelectedCoverage(option)}
                  className={`px-4 py-2 text-center rounded-md font-semibold ${
                    selectedCoverage === option
                      ? "bg-green-500 text-white"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }`}
                >
                  {option}
                </button>
              ))}
            </div>
            {errors.coverage && (
              <span className="text-red-500 text-sm">{errors.coverage}</span>
            )}
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            onClick={handleNextStep}
            className="w-full bg-green-600 text-white py-3 rounded-lg shadow-lg hover:bg-green-700 focus:ring-4 focus:ring-green-300 focus:outline-none transition-transform transform hover:scale-105"
          >
            Get Quote
          </button>
        </form>
      </div>
      <Footer2 />
    </div>
  );
};

export default StepThree2;
