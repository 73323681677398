import React, { useState, useEffect, useRef } from "react";
import happyMan from "../../images/insurance1.jpeg"; // Adjust the path if necessary
import logo from "../../images/logo192.png"; // Replace with the path to your logo
import HowItWorks from "./HowItWorks";
import FAQSection from "./FAQSection";
import Footer from "./Footer";




const CarInsurancePage = () => {
  const carouselRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(1); // Tracks the current step
  const [swipeDirection, setSwipeDirection] = useState("down"); // Tracks the swipe animation direction
  const [hoveredStep, setHoveredStep] = useState(null); // Tracks the currently hovered step
  const [lastHoveredStep, setLastHoveredStep] = useState(null); // Track the last hovered step
  const [activeStep, setActiveStep] = useState(1); // Track the active step (default is 1)
  
  

  // Auto-scroll logic for the carousel
  useEffect(() => {
    const carousel = carouselRef.current;
    let scrollInterval;

    if (carousel) {
      const startAutoScroll = () => {
        scrollInterval = setInterval(() => {
          if (
            carousel.scrollLeft >=
            carousel.scrollWidth - carousel.offsetWidth
          ) {
            carousel.scrollLeft = 0; // Reset to start if the end is reached
          } else {
            carousel.scrollLeft += 1; // Scroll right
          }
        }, 25); // Adjust scroll speed here (lower = faster)
      };

      startAutoScroll();

      // Cleanup interval on component unmount
      return () => clearInterval(scrollInterval);
    }
  }, []);

    const handleStepHover = (stepId) => {
    setHoveredStep(stepId);
    setLastHoveredStep(stepId); // Update the last hovered step
  };

  const handleStepLeave = () => {
    setHoveredStep(null); // Resets the hovered step when the mouse leaves
  };
  

  return (
    <div className="min-h-screen bg-white">
      {/* Header Section */}
      <header className="container mx-auto py-8 px-4">
        <div className="flex items-center space-x-4">
          <img src={logo} alt="Logo" className="h-10" />
          <div className="text-blue-900 text-2xl font-bold">
            USA Insurance Reviews
          </div>
        </div>
      </header>

      {/* Hero Section */}
      <div className="relative">
        <div className="relative overflow-hidden h-[1050px]">
          <img
            src={happyMan}
            alt="Happy man driving"
            className="w-full h-full object-cover"
            style={{
              objectPosition: "10% 5%",
              transform: "scale(2)", // Zoom in the image
              transformOrigin: "left",
            }}
          />
          <div
            className="absolute inset-0"
            style={{
              background: `linear-gradient(to bottom left,
                rgba(23, 56, 124, 0.6),   
                rgb(6, 39, 107)  
              )`,
            }}
          ></div>
        </div>

        {/* Content Overlay */}
        <div className="absolute inset-0 flex flex-col justify-start items-start text-white px-6 pt-6">
          <h1 className="text-2xl md:text-4xl lg:text-6xl font-medium mb-4 leading-snug text-left text-white lg:mt-20 lg:ml-10 tracking-wide">
            Stop overpaying for <br></br>car insurance
          </h1>
          <p className="lg:text-2xl text-left mb-6 lg:mw-3 lg:mt-4 lg:ml-10 md:w-2/3 lg:w-2/5">
            You could save over $800 a year by letting us monitor, shop and
            compare quotes for you to get our best rate—for free.
          </p>
          <img
            alt="traveler insurance quote"
            class="relative lg:absolute lg:translate-x-2/3 lg:translate-y-2/3 lg:w-1/3 lg:h-1/3 lg:ml-80"
            src="https://assets.experiancs.com/images/graphic-auto-savings-comparison.webp?hs=ece7b1e75d569f0af542f5c4d8d80972"
            height="397"
            width="558"
          ></img>
          <button className="bg-pink-600 text-white lg:py-4 lg:px-14 py-3 px-8 rounded text-lg font-semibold shadow-lg hover:bg-pink-600 transition duration-300 mt-6 lg:ml-10 lg:mt-3">
            Get Started
          </button>
          <p className="mt-4 text-med text-center lg:text-left mt-6 lg:ml-10">
            <p>Already a member?</p>{" "}
            <a href="#" className="underline hover:text-white">
              Sign In
            </a>{" "}
            to access additional features like rate monitoring.
          </p>
          <h2 className="text-3xl font-medium text-white text-left mt-5 lg:mt-10 lg:ml-10">
            Why USA Insurance?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-1 gap-4 lg:ml-10">
            <div className="flex items-start space-x-4">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 10h11M9 21V3m6 7h6m-6 4h6m-6 4h6"
                  />
                </svg>
              </div>
              <p className="lg:text-2xl text-white">
                We shop and compare quotes for you from over 40 top insurers.
              </p>
            </div>
            <div className="flex items-start space-x-4">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
              </div>
              <p className="lg:text-2xl text-white">
                Easily switch your coverage online or with an agent over the
                phone.
              </p>
            </div>
            <div className="flex items-start space-x-4">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8 16l4-4m0 0l4-4m-4 4H3m11 0h8"
                  />
                </svg>
              </div>
              <p className="lg:text-2xl text-white">
                No spam calls—we’ll only contact you with permission to help you
                save on insurance.
              </p>
            </div>
            <p className="text-med mt-4 lg:w-1/2">
              Results will vary and some may not see savings. Average savings of
              $828 per year for customers who switched and saved with USA
              Insurance Reviews from Jan. 1, 2022 to Mar. 31, 2024. Savings
              based on customers’ self-reported prior premium. USA Insurance
              Reviews offers insurance from a network of top-rated insurance
              companies
            </p>
          </div>
        </div>
      </div>

      {/* Logos Section */}
      <section className="bg-gray-50 py-12 ">
        <div className="container mx-auto text-center">
          <h3 className="text-xl font-bold mb-6 text-gray-700">
            Compare rates from over 40 top insurers
          </h3>
          <div
            className="flex lg:justify-center lg:space-x-20 space-x-8 overflow-hidden whitespace-nowrap"
            ref={carouselRef}
          >
            {/* Individual Logo */}
            <img
              src="https://assets.experiancs.com/images/logo-progressive-alt.svg?hs=4ba1428fdc32fc4036ad49398cb2f983"
              alt="Progressive"
              className="h-12 lg:h-16 flex-shrink-0 inline-block"
            />
            <img
              src="https://assets.experiancs.com/images/logo-liberty-mutual.svg?hs=9aae2a13a87c6045469575010467c96b"
              alt="Liberty Mutual"
              className="h-12 lg:h-16 flex-shrink-0 inline-block"
            />
            <img
              src="https://assets.experiancs.com/images/logo-nationwide.svg?hs=de940956eb9787b3af0853efa2c9d911"
              alt="Nationwide"
              className="h-12 lg:h-16 flex-shrink-0 inline-block"
            />
            <img
              src="https://assets.experiancs.com/images/logo-mercury.svg?hs=e3a9c99be5ed99da73c80fa514f2a3bb"
              alt="Mercury Insurance"
              className="h-12 lg:h-16 flex-shrink-0 inline-block"
            />
            {/* Duplicate the logos to create an infinite effect */}
            <img
              src="https://assets.experiancs.com/images/logo-progressive-alt.svg?hs=4ba1428fdc32fc4036ad49398cb2f983"
              alt="Progressive"
              className="h-12 lg:h-16 flex-shrink-0 inline-block lg:hidden"
            />
            <img
              src="https://assets.experiancs.com/images/logo-liberty-mutual.svg?hs=9aae2a13a87c6045469575010467c96b"
              alt="Liberty Mutual"
              className="h-12 lg:h-16 flex-shrink-0 inline-block lg:hidden"
            />
            <img
              src="https://assets.experiancs.com/images/logo-nationwide.svg?hs=de940956eb9787b3af0853efa2c9d911"
              alt="Nationwide"
              className="h-12 lg:h-16 flex-shrink-0 inline-block lg:hidden"
            />
            <img
              src="https://assets.experiancs.com/images/logo-mercury.svg?hs=e3a9c99be5ed99da73c80fa514f2a3bb"
              alt="Mercury Insurance"
              className="h-12 lg:h-16 flex-shrink-0 inline-block lg:hidden"
            />
          </div>
        </div>
      </section>
      <div class="h-2 rounded-t-lg lg:p-2 mx-auto max-w-7xl bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500"></div>
      <section class="bg-gray-100 rounded-lg shadow-md p-8 mx-auto max-w-7xl text-center">
        <h2 class="text-2xl lg:text-4xl font-semibold mb-4 lg:text-center text-black">
          Find your same coverage for less
        </h2>
        <p class="text-med lg:text-2xl lg:text-center text-gray-800 text-left mb-8">
          You may be overpaying for car insurance, and shopping for it is a
          hassle. That’s where we come in.
        </p>
        <div class="grid gap-6 md:grid-cols-2">
          <div class="flex flex-col items-center bg-white rounded-lg p-6 shadow">
            <img
              src="https://cdn-icons-png.flaticon.com/128/3917/3917512.png"
              alt="Compare Icon"
              class="w-10 h-10 mb-4"
            />
            <h3 class="text-lg font-semibold mb-2">Compare</h3>
            <p class="text-gray-600">
              We actively monitor rates in our network to make sure you’re
              always getting our best deal.
            </p>
          </div>
          <div class="flex flex-col items-center bg-white rounded-lg p-6 shadow">
            <img
              src="https://cdn-icons-png.flaticon.com/128/3914/3914072.png"
              alt="Save Time Icon"
              class="w-10 h-10 mb-4"
            />
            <h3 class="text-lg font-semibold mb-2">Save time</h3>
            <p class="text-gray-600">
              There’s no need to visit other sites or fill out multiple forms—we
              do it all in one place.
            </p>
          </div>
          <div class="flex flex-col items-center bg-white rounded-lg p-6 shadow">
            <img
              src="https://cdn-icons-png.flaticon.com/128/3916/3916595.png"
              alt="Peace of Mind Icon"
              class="w-12 h-12 mb-4"
            />
            <h3 class="text-lg font-semibold mb-2">Peace of mind</h3>
            <p class="text-gray-600">
              Rest easy knowing you have the right price. We will never sell
              your information, period.
            </p>
          </div>
          <div class="flex flex-col items-center bg-white rounded-lg p-6 shadow">
            <img
              src="https://cdn-icons-png.flaticon.com/128/7928/7928164.png"
              alt="Potential Savings Icon"
              class="w-12 h-12 mb-4"
            />
            <h3 class="text-lg font-semibold mb-2">Potential savings</h3>
            <p class="text-gray-600">
              Let us find you a lower rate—you may even be able to get a partial
              refund from your previous plan!
            </p>
          </div>
        </div>
      </section>
      {/* Add How It Works Section */}
      <HowItWorks />

      <section className="bg-gray-50 py-20">
        <div className="container mx-auto px-6 lg:px-15">
          {/* Top Section: Verified Reviews and Rating */}
          <div className="flex justify-between items-center mb-12">
            {/* Left Side: Title */}
            <h2 className="text-4xl font-bold text-gray-800">
              Verified user reviews
            </h2>

            {/* Right Side: Rating and Stars */}
            <div className="flex items-center text-gray-800">
              <span className="text-6xl font-bold">4.8</span>
              <div className="ml-4 flex items-center">
                {/* Star Ratings */}
                {[...Array(5)].map((_, index) => (
                  <svg
                    key={index}
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-yellow-400"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 .587l3.668 7.431L24 9.751l-6 5.847 1.418 8.266L12 18.902l-7.418 4.962L6 15.598 0 9.751l8.332-1.733z" />
                  </svg>
                ))}
              </div>
            </div>
            <p className="text-gray-600 mt-2">
              <strong>3,666</strong> 3rd Party Verified Reviews
            </p>
          </div>

          {/* Bottom Section: User Testimonials */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Testimonial 1 */}
            <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col">
              <div className="flex items-center mb-4">
                <div className="w-14 h-14 rounded-full bg-gray-200 overflow-hidden">
                  <img
                    src="https://via.placeholder.com/100"
                    alt="Olivia T."
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="ml-4">
                  <p className="text-lg font-bold text-pink-600">Olivia T.</p>
                  <p className="text-sm text-gray-500">Saved $322</p>
                </div>
              </div>
              <blockquote className="text-gray-700 italic">
                “As a school teacher, it's difficult to find time during the day
                to call multiple insurance agents. After a simple signup step,
                they did all the rest to get me the best rate.”
              </blockquote>
            </div>

            {/* Testimonial 2 */}
            <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col">
              <div className="flex items-center mb-4">
                <div className="w-14 h-14 rounded-full bg-gray-200 overflow-hidden">
                  <img
                    src="https://via.placeholder.com/100"
                    alt="Barry B."
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="ml-4">
                  <p className="text-lg font-bold text-pink-600">Barry B.</p>
                  <p className="text-sm text-gray-500">Saved $795</p>
                </div>
              </div>
              <blockquote className="text-gray-700 italic">
                “Switching from my previous insurer was very easy. They saved me
                around $800 a year! I recommend to anyone who is looking to save
                money on their insurance needs.”
              </blockquote>
            </div>
          </div>
        </div>
      </section>
      <div className="bg-gray-50 py-12">
        <div className="container mx-auto flex flex-col lg:flex-row items-center lg:justify-between px-6 lg:px-14">
          {/* Text Section */}
          <div className="text-center lg:text-left lg:w-1/2">
            <h2 className="text-3xl lg:text-4xl font-semibold text-gray-800 mb-4">
              Choose policies from over <br /> 40 top insurers
            </h2>
            <p className="text-gray-600 text-lg">
              We make it simple to find the best insurance options with trusted
              providers.
            </p>
          </div>

          {/* Image Section */}
          <div className="relative lg:w-1/2 mt-8 lg:mt-0 flex justify-center">
            <img
              src="https://assets.experiancs.com/images/graphic-top-insurers.webp?hs=30190f323a3e9e1d7dea9048675feaac"
              alt="Top Insurers"
              className="w-full max-w-lg lg:max-w-xl shadow-lg rounded-lg"
            />
          </div>
        </div>
      </div>
      {/* FAQ Section */}
      <FAQSection />
      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default CarInsurancePage;

