import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import index from "../../index.css";

const Footer2 = () => {
  const location = useLocation();

  useEffect(() => {
    // If the current page is NOT "/2", fire the pixel event
    if (location.pathname !== "/2") {
      fbq("track", "ViewContent");
    }
  }, [location.pathname]);

  return (
    <footer className="bg-gradient-to-r from-gray-900 to-gray-800 text-gray-300 py-8 px-4">
      {/* Footer Links */}
      <div className="flex flex-wrap justify-center space-x-4 mb-6 text-sm font-medium">
        <a
          href="/2"
          className="hover:text-white transition-colors duration-300"
        >
          Privacy Center
        </a>
        <span className="text-gray-500">|</span>
        <a
          href="/2"
          className="hover:text-white transition-colors duration-300"
        >
          Terms of Use
        </a>
        <span className="text-gray-500">|</span>
        <a
          href="/2"
          className="hover:text-white transition-colors duration-300"
        >
          Your Privacy Rights
        </a>
        <span className="text-gray-500">|</span>
        <a
          href="/2"
          className="hover:text-white transition-colors duration-300"
        >
          Contact Us
        </a>
      </div>

      {/* Footer Content */}
      <div className="max-w-5xl mx-auto text-center text-sm space-y-4">
        <p>
          <strong className="text-white">Usainsurancereviews.com</strong> is a
          top savings referral service enabling consumers to request competitive
          insurance quotes from local insurance agents and companies using
          proprietary alignment technology. Our site does not provide quotes
          directly to consumers. We do not provide insurance and do not
          represent any specific insurance provider.
        </p>
        <p>
          Lowest rates and potential savings may not be representative or
          available from all companies. Rates depend on various factors
          including location, coverage limits, deductibles, driving records, and
          more. The rates displayed are based on specific scenarios and may not
          reflect all discounts.
        </p>
        <p>
          Logos displayed represent carriers we work with but may not be
          available in all states. Carriers include: Allstate Corporation, 3100
          Sanders Road Northbrook, IL 60062; The General Automobile Insurance
          Services Inc., 2636 Elm Hill Pike, Suite 510, Nashville, Tennessee
          37214; Liberty Mutual Insurance Company, 175 Berkeley Street Boston,
          Massachusetts 02116; Progressive Corporation, 6300 Wilson Mills Rd.,
          Mayfield Village, OH 44143.
        </p>
        <p>© 2024 Usainsurancereviews.com. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer2;
