import React, { useEffect, useState, useContext } from "react";
import Header2 from "../UserFlowTwo/Header2";
import Footer2 from "../UserFlowTwo/Footer2";
import "../../styles/flowTwo/OffersPage2.module.css";
import { CSSTransition } from "react-transition-group";
import { FormDataContext } from "../../contexts/FormDataContext";
import { getSessionItem } from "../../utils/sessionStorageUtil";

const OffersPage2 = () => {
  const { formData } = useContext(FormDataContext);
  const [cityName, setCityName] = useState("your area");
  const [timeLeft, setTimeLeft] = useState(60); // 1-minute timer

  useEffect(() => {
    const zip = getSessionItem("zip");
    if (zip !== "00000") {
      fetchCityName(zip);
    }

    // Timer countdown
    const timer = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const dsp_name = localStorage.getItem("dsp_name");

    // Extract only the necessary data from the drivers arrays
    const drivers = formData.drivers.map((driver) => {
      return {
        driver: driver.driver,
        birth_date: driver.birth_date,
        credit_rating: driver.credit_rating,
        sr_22: driver.sr_22,
      };
    });

    // Extract necessary parameters from formData or localStorage
    const zip = getSessionItem("zip");
    const vehicles = formData.vehicles;
    const contact = formData.contact;
    const address = formData.address;
    const address2 = formData.address2;
    const email = formData.email;
    const phone = formData.phone;
    const coverage_type = formData.coverage_type;
    const currently_insured = formData.currently_insured;
    const current_company = formData.current_company;
    const military_affiliation = formData.military_affiliation;
    const continuous_coverage = formData.continuous_coverage;
    const home_ownership = formData.home_ownership;
    const campaignName =
      localStorage.getItem("campaign_name") || "default_campaign";
    const campaignId = localStorage.getItem("campaign_id") || "default_id";
    const clickId = localStorage.getItem("click_id") || "default_click";

    // Create the MediaAlpha ad configuration object
    const mediaAlphaConfig = {
      placement_id:
        dsp_name === "google"
          ? "fD4petSMP9HeM2IAYex88RKg0siJ_Q"
          : "k7ImWb8FHOIBYxJQPmKSAUiyBQx_kg",
      type: "ad_unit",
      version: 17,
      sub_1: campaignName,
      sub_2: campaignId,
      sub_3: clickId,
      data: {
        contact: contact,
        zip: zip,
        vehicles: vehicles,
        drivers: drivers,
        address: address,
        address_2: address2,
        email: email,
        phone: phone,
        coverage_type: coverage_type,
        currently_insured: currently_insured,
        current_company: current_company,
        military_affiliation: military_affiliation,
        continuous_coverage: continuous_coverage,
        home_ownership: home_ownership,
      },
    };

    // Dynamically load the MediaAlpha script
    const loadMediaAlphaScript = () => {
      return new Promise((resolve, reject) => {
        // Check if the script is already loaded
        if (document.getElementById("mediaalpha-script")) {
          resolve();
          return;
        }

        const mediaAlphaScript = document.createElement("script");
        mediaAlphaScript.id = "mediaalpha-script";
        mediaAlphaScript.src = "//insurance.mediaalpha.com/js/serve.js";
        mediaAlphaScript.async = true;

        mediaAlphaScript.onload = () => {
          console.log("MediaAlpha script loaded successfully.");
          resolve();
        };
        mediaAlphaScript.onerror = (error) => {
          console.error("Failed to load MediaAlpha script.", error);
          reject(error);
        };

        document.body.appendChild(mediaAlphaScript);

        // If Facebook dsp_name, track lead
        if (dsp_name === "facebook" && typeof fbq === "function") {
          fbq("track", "Lead");
        }
      });
    };

    // Add click event listener for conversion tracking
    const addConversionTracking = () => {
      const mediaalphaPlaceholder = document.getElementById(
        "mediaalpha_placeholder"
      );

      if (mediaalphaPlaceholder) {
        mediaalphaPlaceholder.addEventListener("click", () => {
          console.log("Ad clicked. Checking traffic source...");

          if (dsp_name === "google") {
            console.log(
              "Google traffic detected. Firing Google conversion pixel..."
            );
            if (typeof window.gtag === "function") {
              window.gtag("event", "conversion", {
                send_to: "AW-1007684690", // Replace with your actual Google Ads conversion ID
              });
            } else {
              console.warn(
                "Google gtag function is not available. Conversion tracking failed."
              );
            }
          } else {
            console.log(
              "Non-Google traffic detected. Firing Facebook pixel..."
            );
            if (typeof window.fbq === "function") {
              window.fbq("track", "Purchase");
            } else {
              console.warn(
                "Facebook pixel tracking function is not available. Conversion tracking failed."
              );
            }
          }
        });
      } else {
        console.warn(
          "MediaAlpha placeholder not found. Conversion tracking could not be added."
        );
      }
    };

    // Set up the ad unit
    const setupAdUnit = async () => {
      try {
        await loadMediaAlphaScript();

        if (typeof window.MediaAlphaExchange__load === "function") {
          window.MediaAlphaExchange = mediaAlphaConfig;
          window.MediaAlphaExchange__load("mediaalpha_placeholder");
        } else {
          console.error("MediaAlphaExchange__load function is not defined.");
        }

        addConversionTracking();
      } catch (error) {
        console.error(
          "An error occurred while setting up the MediaAlpha ad:",
          error
        );
      }
    };

    setupAdUnit();

    // Fetch city name from ZIP code if valid
    if (zip !== "00000") {
      fetchCityName(zip);
    }

    // Function to add star ratings
    const addStarRatings = () => {
      const offerElements = document.querySelectorAll(
        ".mav-partner.js-media-alpha-partner.mav-partner__standard.collapsible.collapsed, .mav-partner.js-media-alpha-partner.mav-partner__featured.collapsible.collapsed"
      );

      offerElements.forEach((offerElement, index) => {
        if (offerElement.querySelector(".star-overlay")) {
          return; // Avoid duplicates
        }

        const starOverlay = document.createElement("div");
        starOverlay.className = "star-overlay";
        starOverlay.setAttribute("data-rating", `offer-${index}`);

        let rating = 5 - index;
        if (rating < 1) {
          rating = 1; // Minimum one star
        }

        for (let i = 1; i <= 5; i++) {
          const star = document.createElement("span");
          star.className = "star" + (i <= rating ? " filled" : "");
          star.innerHTML = "★";
          starOverlay.appendChild(star);
        }

        offerElement.style.position = "relative";
        offerElement.appendChild(starOverlay);
      });
    };

    // Use MutationObserver to detect changes and call addStarRatings when offers load
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          console.log("Mutation detected:", mutation);
          addStarRatings();
        }
      });
    });

    const targetNode = document.getElementById("mediaalpha_placeholder");
    if (targetNode) {
      observer.observe(targetNode, { childList: true, subtree: true });
    }
  }, [formData]); // Re-run if formData changes

  const fetchCityName = async (zipCode) => {
    try {
      const response = await fetch(`https://api.zippopotam.us/us/${zipCode}`);
      if (response.ok) {
        const data = await response.json();
        const city = data.places[0]["place name"];
        setCityName(city);
      }
    } catch (error) {
      console.error("Failed to fetch city name:", error);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <CSSTransition
      in={true}
      appear={true}
      timeout={1500}
      classNames="fade-slide"
    >
      <div className="min-h-screen bg-gradient-to-b from-gray-100 to-gray-200">
        <Header2 />
        <div className="max-w-4xl mx-auto py-8 px-6 text-center">
          {/* Header Section */}
          <h2 className="text-4xl font-bold text-gray-800 mb-4 text-center">
            We've Matched You with Great Offers in {cityName}!
          </h2>

          {/* Timer Section */}
          <div className="bg-white shadow-md rounded-lg p-4 inline-block mb-8">
            <p className="text-xl font-semibold text-gray-800">
              Lock in your rates before they disappear!
            </p>
            <p className="text-3xl font-bold text-red-600 mt-2">
              {formatTime(timeLeft)}
            </p>
          </div>
          <p className="text-lg text-gray-600 mb-6">
            Choose from one of our top-rated partners and start saving money
            today!
          </p>

          {/* Offers List Placeholder */}
          <div className="bg-white shadow-lg rounded-lg p-6">
            <div
              id="mediaalpha_placeholder"
              className="mediaalpha-container"
            ></div>
          </div>

          <p className="text-gray-500 text-sm mt-4">
            Powered by{" "}
            <span className="text-green-600 font-semibold">
              usainsurancereviews.com
            </span>
          </p>
        </div>
        <Footer2 />
      </div>
    </CSSTransition>
  );
};

export default OffersPage2;
